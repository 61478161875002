import { Store } from 'libx'
import env from 'misc/env'
import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'
import Legend from './Legend'
import BriefLegend from './BriefLegend'
import LegendVersion from './LegendVersion'
import * as ducttape from './ducttape'
import { createLegendClient } from '../api/LegendAPI'
import {
  ENGINE_VERSION_PARAMS,
  SUPPORTED_ENGINE_VERSION,
} from '../../consts/EngineVersion'

const makeVersionId = (id, version) => `${id}:${version}`

export default class LegendStore extends Store {
  api = createLegendClient({
    baseURL: env.LEGEND_API,
    getToken: this.rootStore.authStore.getToken,
  })

  legends = this.collection({
    model: Legend,
  })

  briefLegends = this.collection({
    model: BriefLegend,
  })

  legendVersions = this.collection({
    model: LegendVersion,
    getModelId: (m) => makeVersionId(m.id, m.version),
    getDataId: (d) => makeVersionId(d.id, d.version),
  })

  /**
   * When a legend has been published, we can store it here so we won't have to fetch
   * it again because published legends are never changed.
   */
  cachedLegendVersions = new Map()

  constructor() {
    super(...arguments)
    this.rootStore.sessionStore.onWorkspaceSwitched(() => {
      this.legendVersions.clear()
      this.legends.clear()
      this.briefLegends.clear()
      this.cachedLegendVersions = new Map()
    })
  }

  /**
   * Gets an ordered (highest version first) list of legend version.
   *
   * @param {*} legendId
   */
  versionsFor(legendId) {
    const versions = this.legendVersions.filter((v) => v.id === legendId)
    return orderBy(versions, 'version', 'desc')
  }

  /**
   * Filters legends based on workspace ID.
   *
   * @param {*} workspaceId
   */
  legendsForWorkspace(workspaceId) {
    workspaceId = workspaceId || this.rootStore.sessionStore.workspace.id
    return this.legends.filter((l) => l.workspace_id === workspaceId)
  }

  /**
   * Filters brief legends based on workspace id.
   *
   * @param {*} workspaceId
   */
  briefLegendsForWorkspace(workspaceId) {
    workspaceId = workspaceId || this.rootStore.sessionStore.workspace?.id
    if (!workspaceId) {
      return []
    }
    return this.briefLegends.filter((l) => l.workspaceId === workspaceId)
  }

  /**
   * Filters brief TAX or LEGACY type legends based on workspace id.
   *
   * @param {*} workspaceId
   */
  briefTaxOrLegacyTypeLegendsForWorkspace(workspaceId) {
    workspaceId = workspaceId || this.rootStore.sessionStore.workspace?.id
    if (!workspaceId) {
      return []
    }

    // serviceType is undefined for LEGACY legends.
    const filteredWorkspaceLegends = this.briefLegends.filter(
      (l) =>
        l.workspaceId === workspaceId &&
        (l.serviceType === 'TAX' || l.serviceType === undefined)
    )

    return filteredWorkspaceLegends
  }

  /**
   * Filters brief CONSULTATION type legends based on workspace id.
   *
   * @param {*} workspaceId
   */
  briefConsultationLegendsForWorkspace(workspaceId) {
    workspaceId = workspaceId || this.rootStore.sessionStore.workspace?.id
    if (!workspaceId) {
      return []
    }

    const filteredWorkspaceLegends = this.briefLegends.filter(
      (l) => l.workspaceId === workspaceId && l.serviceType === 'CONSULTATION'
    )

    return filteredWorkspaceLegends
  }

  async fetchLegends(params) {
    const found = await this.api.find({
      workspace_id: this.rootStore.sessionStore.workspace.id,
      inactive: true,
      draft: true,
      ...params,
    })
    return {
      ...found,
      data: this.legends.set(found.data),
    }
  }

  async fetchLegendsForWorkspace(workspaceId) {
    const found = await this.api.findAdmin({
      workspace_id: workspaceId,
      inactive: false,
      draft: true,
    })
    return {
      ...found,
      data: this.briefLegends.set(found.data),
    }
  }

  async fetchLegendVersions(legendId, params) {
    return this.api
      .getVersions(legendId, { ...ENGINE_VERSION_PARAMS, ...params })
      .then((r) => {
        const data = this.legendVersions.set(r.data)
        return {
          ...r,
          data,
        }
      })
  }

  async fetchLegendVersion(legendId, version, skipCache) {
    const cacheKey = makeVersionId(legendId, version)
    if (version && !skipCache) {
      const cached = this.cachedLegendVersions.get(cacheKey)
      if (cached) {
        return cached
      }
    }

    return this.api
      .getVersion(legendId, version || 'latest', ENGINE_VERSION_PARAMS)
      .then((r) => {
        this.legends.set(r)
        const legendVersion = this.legendVersions.set(r)
        this.cachedLegendVersions.set(
          makeVersionId(legendId, legendVersion.version),
          legendVersion
        )
        return legendVersion
      })
  }

  async create(data) {
    const updated = await this.api.create(
      {
        ...data,
        workspace_id: this.rootStore.sessionStore.workspace.id,
      },
      ENGINE_VERSION_PARAMS
    )
    return this.legends.set(updated)
  }

  async save(id, data) {
    const cloned = ducttape.toLegacyLegend(
      cloneDeep({
        ...data,
        engine_version: SUPPORTED_ENGINE_VERSION,
        id,
        workspace_id: this.rootStore.sessionStore.workspace.id,
      })
    )

    const result = await this.api.save(cloned)
    const legendVersion = this.legendVersions.get(
      makeVersionId(cloned.id, cloned.version)
    )

    if (legendVersion) {
      legendVersion.set({
        concurrency_version: result.concurrency_version,
        date_modified: result.date_modified,
      })
    }

    this.legends.get(id).set({
      concurrency_version: result.concurrency_version,
    })
    return {
      errors: result.errors,
    }
  }

  async patch(id, data) {
    const cloned = ducttape.toLegacyLegend(
      cloneDeep({
        ...data,
        engine_version: SUPPORTED_ENGINE_VERSION,
        id,
        workspace_id: this.rootStore.sessionStore.workspace.id,
      })
    )

    const result = await this.api.patch(cloned)
    const legendVersion = this.legendVersions.get(
      makeVersionId(cloned.id, cloned.version)
    )

    if (legendVersion) {
      legendVersion.set({
        concurrency_version: result.concurrency_version,
        date_modified: result.date_modified,
      })
    }

    this.legends.get(id).set({
      concurrency_version: result.concurrency_version,
    })
    return {
      errors: result.errors,
    }
  }

  async publish(payload) {
    const result = await this.api.publish(payload, ENGINE_VERSION_PARAMS)
    this.legends.set(result)
    return this.legendVersions.get(makeVersionId(payload.id, payload.version))
  }

  async activate(id) {
    const legend = this.legends.get(id)
    return this.api.activate(id).then((l) => {
      return legend.set(l)
    })
  }

  async deactivate(id) {
    const legend = this.legends.get(id)
    return this.api.deactivate(id).then((l) => {
      return legend.set(l)
    })
  }

  async destroy(id) {
    this.legends.remove(id)
    await this.api.remove(id)
  }
}
