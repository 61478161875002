import { validatable } from '../mixins/validation'
import { observable } from 'mobx'
import { serializable, snapshotable } from '../data/serialization'
import { receiveErrors } from '../data/serverErrors'

/**
 * Message config editor.
 */
@snapshotable
@validatable({ liveValidation: true })
export default class MessageConfigEditor {
  @observable
  @serializable
  welcomeMessageEnabled = false

  @observable
  @serializable
  welcomeMessageText = ''

  constructor(legendEditor) {
    this.legendEditor = legendEditor
  }

  hydrate(messageConfig) {
    if (!messageConfig) {
      return
    }

    const { welcome_message } = messageConfig

    this.welcomeMessageEnabled = !!welcome_message?.enabled
    this.welcomeMessageText = welcome_message?.text ?? ''
  }

  toJSON() {
    return {
      welcome_message: {
        enabled: this.welcomeMessageEnabled,
        text: this.welcomeMessageText,
      },
    }
  }

  receiveErrors(descriptor = {}) {
    const { inner = {} } = descriptor
    const { categories, ...rest } = inner
    receiveErrors(this, rest)
  }
}
