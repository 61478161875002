import React, { Component } from 'react'
import { observer } from 'mobx-react'

import env from 'misc/env'
import { FlexContainer, FlexItem } from 'components/Flex'
import Avatar from 'components/Avatar'
import Bullet from 'components/Bullet'
import FloatingLabel from 'components/FloatingLabel'
import { Widget, WidgetContent } from 'components/Widget'
import './UserCard.sass'
import RequirePerms from 'iam/containers/RequirePerms'
import cx from 'classnames'

const CustomBullet = ({ children, ...props }) => (
  <Bullet color="#A776E5" {...props}>
    {children}
  </Bullet>
)

@observer
class UserCard extends Component {
  render() {
    const {
      user,
      title,
      titleTheme,
      teamName,
      'data-test': dataTest,
      hidePersonalInformation = false,
    } = this.props
    return (
      <Widget className="user-card">
        <WidgetContent>
          {title && (
            <FloatingLabel
              theme={titleTheme}
              className="user-card__title-label"
            >
              {title}
            </FloatingLabel>
          )}
          {teamName && (
            <FloatingLabel
              theme={titleTheme}
              className="user-card__title-label-right"
            >
              {teamName}
            </FloatingLabel>
          )}

          <FlexContainer className="user-card__card-container">
            <FlexItem
              className="user-card__user-image"
              onClick={() => this.openInWorkPortal(user)}
            >
              <Avatar
                url={user.avatar}
                fallback={user.avatarFallback}
                size={92}
              >
                <FlexItem
                  className={cx(
                    'user-card__online-indicator',
                    user.online && 'online'
                  )}
                ></FlexItem>
              </Avatar>
            </FlexItem>
            <FlexItem className="user-card__user-info" stretch>
              <h1
                className="title is-3 user-card__name"
                onClick={() => this.openInWorkPortal(user)}
                data-test={dataTest}
              >
                {user.displayName}
              </h1>
              <div>
                {!hidePersonalInformation && (
                  <RequirePerms allOf={['ADMIN_USER_READ']}>
                    <CustomBullet>{user.email}</CustomBullet>
                    <CustomBullet>{user.userId}</CustomBullet>
                    {user.phone && <CustomBullet>{user.phone}</CustomBullet>}
                  </RequirePerms>
                )}
              </div>
            </FlexItem>
          </FlexContainer>
        </WidgetContent>
      </Widget>
    )
  }

  openInWorkPortal(user) {
    const waitFn = `document.write('Please wait, generating user URL.')`
    const newTab = window.open('javascript:' + waitFn, '_blank')
    newTab.location.assign(
      `${env.PROVIDER_PORTAL_URL}/users/${user.userPublicId}`
    )
  }
}

export default UserCard
