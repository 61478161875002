import { observable, action, computed } from 'mobx'

export default class CombinedPricingConfigViewModel {
  @observable
  jobScopeChangeRequestsEnabled = false

  @observable
  jobScopeChangeNotificationSlackChannelIds = ''

  @observable
  jobScopeRequireClientApproval = false

  @observable
  jobScopePromptClientApproval = false

  @observable
  jobScopeChangeUpfrontCharge = false

  @observable
  legends = {}

  @observable
  workspaceLegends = []

  activate(
    jobScopeChangeRequestsEnabled = false,
    jobScopeChangeNotificationSlackChannelIds = [],
    jobScopeRequireClientApproval = false,
    jobScopePromptClientApproval = false,
    jobScopeChangeUpfrontCharge = false,
    legends = {},
    workspaceLegends = []
  ) {
    this.jobScopeChangeRequestsEnabled = jobScopeChangeRequestsEnabled
    this.jobScopeChangeNotificationSlackChannelIds = jobScopeChangeNotificationSlackChannelIds.join(
      ', '
    )
    this.jobScopeRequireClientApproval = jobScopeRequireClientApproval
    this.jobScopePromptClientApproval = jobScopePromptClientApproval
    this.jobScopeChangeUpfrontCharge = jobScopeChangeUpfrontCharge
    this.workspaceLegends = workspaceLegends

    this.legends = this.initializeLegends(legends, workspaceLegends)
  }

  @computed
  get upfrontChargeRates() {
    const rates = this.workspaceLegends
      .filter((legend) => legend.inactive === false)
      .map((legend) => {
        return Object.create({
          id: legend.id,
          name: legend.name,
          draft: !legend.published,
        })
      })

    return rates
  }

  @action.bound
  initializeLegends(initialLegends, workspaceLegends) {
    const workspaceLegendIds = workspaceLegends
      .filter((legend) => legend.inactive === false)
      .map((legend) => legend.id)
    const legends = Object.assign({}, initialLegends)

    workspaceLegendIds.forEach((id) => {
      if (!Object.hasOwn(legends, id)) {
        legends[id] = 0
      }
    })

    return legends
  }

  toJSON() {
    const parsedRates = Object.assign({}, this.legends)
    for (const legend in parsedRates) {
      const parsedRateValue = parseFloat(parsedRates[legend])
      if (typeof parsedRateValue === 'number' && !isNaN(parsedRateValue)) {
        parsedRates[legend] = Math.min(Math.max(parsedRateValue, 0), 1)
      } else {
        parsedRates[legend] = 0
      }
    }

    return {
      job_scope_change_requests_enabled: this.jobScopeChangeRequestsEnabled,
      job_scope_change_notification_slack_channel_ids: this.jobScopeChangeNotificationSlackChannelIds
        .split(',')
        .map((id) => id.trim()),
      job_scope_require_client_approval: this.jobScopeRequireClientApproval,
      job_scope_prompt_client_approval: this.jobScopePromptClientApproval,
      job_scope_change_upfront_charge: this.jobScopeChangeUpfrontCharge,
      legends: parsedRates,
    }
  }
}
