import { createMessagingAPI } from '@taxfyle/web-commons/lib/messaging/API'
import MultiWorkspaceNotificationStore from '@taxfyle/web-commons/lib/notifications/MultiWorkspaceNotificationStore'
import NotificationPreferencesStore from '@taxfyle/web-commons/lib/notifications/NotificationPreferencesStore'
import NotificationStore from '@taxfyle/web-commons/lib/notifications/NotificationStore'
import { createIamAPI } from '@taxfyle/web-commons/lib/iam/API'
import { UserIdLookup } from '@taxfyle/web-commons/lib/iam/UserIdLookup'
import { WorkRealtime } from '@taxfyle/web-commons/lib/work-realtime/WorkRealtime'
import CouponStore from 'billing/CouponStore'
import UserCouponStore from 'billing/UserCouponStore'
import DocumentAccessStore from 'documents/DocumentAccessStore'
import DocumentStore from 'documents/DocumentStore'
import DocumentTagStore from 'documents/DocumentTagStore'
import DocumentVersionStore from 'documents/DocumentVersionStore'
import FlashMessageStore from 'flash-messages/FlashMessageStore'
import InvitationStore from 'iam/InvitationStore'
import MemberStore from 'iam/MemberStore'
import PermissionStore from 'iam/PermissionStore'
import RoleStore from 'iam/RoleStore'
import SkillEditorStore from 'iam/SkillEditorStore'
import SkillStore from 'iam/SkillStore'
import SkillTagStore from 'iam/SkillTagStore'
import TeamMemberStore from 'iam/TeamMemberStore'
import TeamRoleStore from 'iam/TeamRoleStore'
import TeamStore from 'iam/TeamStore'
import TranslationStore from 'iam/TranslationStore'
import UserStore from 'iam/UserStore'
import UserDataStore from 'iam/UserDataStore'
import WorkspaceSelectorStore from 'iam/WorkspaceSelectorStore'
import WorkspaceStore from 'iam/WorkspaceStore'
import WorkspaceSecretStore from 'iam/WorkspaceSecretStore'
import MilestoneStore from 'jobs/MilestoneStore'
import PromptStore from 'jobs/PromptStore'
import JobProgressionStore from 'jobs/JobProgressionStore'
import PromptDialogStore from 'jobs/PromptDialogStore'
import SendPromptDialogStore from 'jobs/SendPromptDialogStore'
import QuestionStore from './QuestionStore'
import { CallingConversationViewModel } from 'messaging/calling/CallingConversationViewModel'
import CallStore from 'messaging/calling/CallStore'
import env from 'misc/env'
import { createEncryptedStorage, sessionStorage } from 'misc/storage'
import SessionStore from 'session/SessionStore'
import WorkspaceManagementStore from 'workspace-management/WorkspaceManagementStore'
import LegendEditorViewStore from '../legend-builder/data/LegendEditorViewStore'
import LegendStore from '../legend-builder/data/LegendStore'
import LegendsViewStore from '../legend-builder/data/LegendsViewStore'
import API from '../misc/API'
import PublishedLegendStore from '../published-legends/PublishedLegendStore'
import CreateJobEditorStore from '../screens/UserDetails/CreateJobModal/CreateJobEditorStore'
import ScopeWorkEditorStore from '../screens/UserDetails/ScopeWorkModal/ScopeWorkEditorStore'
import AuthStore from './AuthStore'
import BillingStore from './BillingStore'
import PayoutMethodStore from './PayoutMethodStore'
import EventStore from './EventStore'
import HQTeamManagementStore from './HQTeamManagementStore'
import JobStore from './JobStore'
import MessageStore from './MessageStore'
import MessageBankStore from './MessageBankStore'
import NotificationActionStore from './NotificationActionStore'
import ScopeWorkStore from './ScopeWorkStore'
import AddRolesStore from './ui/AddRolesStore'
import AddSkillsStore from './ui/AddSkillsStore'
import AddToTeamStore from './ui/AddToTeamStore'
import AnalyticsScreenStore from './ui/AnalyticsScreenStore'
import CouponsScreenStore from './ui/CouponsScreenStore'
import DesktopNotificationStore from './ui/DesktopNotificationStore'
import DocumentTagEditorStore from './ui/DocumentTagEditorStore'
import DocumentTagsScreenStore from './ui/DocumentTagsScreenStore'
import EditUserStore from './ui/EditUserStore'
import JobDetailsStore from './ui/JobDetailsStore'
import JobsScreenStore from './ui/JobsScreenStore'
import RoleEditorStore from './ui/RoleEditorStore'
import RolesScreenStore from './ui/RolesScreenStore'
import ScopingJobsScreenStore from './ui/ScopingJobsScreenStore'
import SidebarStore from './ui/SidebarStore'
import SkillsScreenStore from './ui/SkillsScreenStore'
import SkillTagEditorStore from './ui/SkillTagEditorStore'
import SkillTagsScreenStore from './ui/SkillTagsScreenStore'
import UserDetailsScreenStore from './ui/UserDetailsScreenStore'
import UserPermissionsEditorStore from './ui/UserPermissionsEditorStore'
import UserRolesEditorStore from './ui/UserRolesEditorStore'
import UserSearchStore from './ui/UserSearchStore'
import MessageBankScreenStore from './ui/MessageBankScreenStore'
import InventoryItemStore from './InventoryItemStore'
import DraftStore from 'drafts/DraftStore'
import BundleStore from './BundleStore'
import { BehaviorSubject } from 'rxjs'
import { find } from 'rxjs/operators'

const storagePassword = 'wOQ*3`OgTxm$=f%ynv6hFNF=A'

export default class RootStore {
  /**
   * Sets up the root application store.
   * It references all stores in the app.
   */

  _togglesInitialized = new BehaviorSubject()

  togglesInitialized$ = this._togglesInitialized
    .asObservable()
    .pipe(find(Boolean))

  constructor() {
    this.getTime = () => new Date()

    const makeOpts = (other) => ({ rootStore: this, ...other })

    this.storage = createEncryptedStorage(storagePassword, sessionStorage)
    this.authStore = new AuthStore(makeOpts({ storage: this.storage }))

    this.messagingAPI = createMessagingAPI({
      baseURL: env.MESSAGE_API,
      getToken: this.authStore.getToken,
      token$: this.authStore.token$,
    })

    this.iamAPI = createIamAPI({
      baseURL: env.IAM_API,
      getToken: this.authStore.getToken,
    })

    this.workRealtime$ = WorkRealtime({
      baseURL: env.WORK_DOTNET_API,
      token$: this.authStore.token$,
    })

    this.userIdLookup = UserIdLookup(this.iamAPI.users)

    this.userStore = new UserStore(makeOpts({ storage: this.storage }))
    this.userDataStore = new UserDataStore(makeOpts())
    this.workspaceStore = new WorkspaceStore(makeOpts())
    this.workspaceSecretStore = new WorkspaceSecretStore(makeOpts())
    this.memberStore = new MemberStore(makeOpts())
    this.sessionStore = new SessionStore(makeOpts())
    this.workspaceSelectorStore = new WorkspaceSelectorStore(makeOpts())
    this.permissionStore = new PermissionStore(makeOpts())
    this.roleStore = new RoleStore(makeOpts())
    this.invitationStore = new InvitationStore(makeOpts())
    this.skillStore = new SkillStore(makeOpts())
    this.translationStore = new TranslationStore(makeOpts())
    this.api = new API(this)
    // This store is used for the Legend Builder
    this.legendStore = new LegendStore(makeOpts())
    // This store is used for selecting Published Legends for jobs.
    this.publishedLegendStore = new PublishedLegendStore(makeOpts())

    this.flashMessageStore = new FlashMessageStore(makeOpts())
    this.desktopNotificationStore = new DesktopNotificationStore(makeOpts())
    this.sidebarStore = new SidebarStore(makeOpts())

    this.teamStore = new TeamStore(makeOpts())
    this.teamMemberStore = new TeamMemberStore(makeOpts())
    this.teamRoleStore = new TeamRoleStore(makeOpts())
    this.teamManagementStore = new HQTeamManagementStore(makeOpts())
    this.billingStore = new BillingStore(makeOpts())
    this.payoutMethodStore = new PayoutMethodStore(makeOpts())
    this.promptStore = new PromptStore(makeOpts())
    this.jobProgressionStore = new JobProgressionStore(makeOpts())

    this.couponStore = new CouponStore(makeOpts())
    this.draftStore = new DraftStore(makeOpts())
    this.jobStore = new JobStore(makeOpts())
    this.scopeWorkStore = new ScopeWorkStore(makeOpts())
    this.milestoneStore = new MilestoneStore(makeOpts())
    this.promptDialogStore = new PromptDialogStore(makeOpts())
    this.sendPromptDialogStore = new SendPromptDialogStore(makeOpts())
    this.eventStore = new EventStore(makeOpts())
    this.questionStore = new QuestionStore(makeOpts())
    this.skillStore = new SkillStore(makeOpts())
    this.inventoryItemStore = new InventoryItemStore(makeOpts())
    this.bundleStore = new BundleStore(makeOpts())
    this.documentStore = new DocumentStore(makeOpts())
    this.documentTagStore = new DocumentTagStore(makeOpts())
    this.documentAccessStore = new DocumentAccessStore(makeOpts())
    this.documentVersionStore = new DocumentVersionStore(makeOpts())
    this.userCouponStore = new UserCouponStore(makeOpts())
    this.messageStore = new MessageStore(makeOpts())
    this.callStore = new CallStore(makeOpts({ storage: this.storage }))
    this.callingConversationViewModel = new CallingConversationViewModel(
      makeOpts({ storage: this.storage })
    )
    this.userSearchStore = new UserSearchStore(makeOpts())
    this.couponsScreenStore = new CouponsScreenStore(makeOpts())
    this.jobsScreenStore = new JobsScreenStore(makeOpts())
    this.scopingJobsScreenStore = new ScopingJobsScreenStore(makeOpts())
    this.addRolesStore = new AddRolesStore(makeOpts())
    this.addSkillsStore = new AddSkillsStore(makeOpts())
    this.skillsScreenStore = new SkillsScreenStore(makeOpts())
    this.skillEditorStore = new SkillEditorStore(makeOpts())
    this.userDetailsScreenStore = new UserDetailsScreenStore(
      makeOpts({
        isPayoutsEnabled: isPayoutsEnabled(),
      })
    )
    this.jobDetailsStore = new JobDetailsStore(makeOpts())
    this.editUserStore = new EditUserStore(makeOpts())
    this.legendsViewStore = new LegendsViewStore(makeOpts())
    this.roleEditorStore = new RoleEditorStore(makeOpts())
    this.rolesScreenStore = new RolesScreenStore(makeOpts())
    this.userRolesEditorStore = new UserRolesEditorStore(makeOpts())
    this.userPermissionsEditorStore = new UserPermissionsEditorStore(makeOpts())
    this.createJobEditorStore = new CreateJobEditorStore(makeOpts())
    this.scopeWorkEditorStore = new ScopeWorkEditorStore(makeOpts())
    this.addToTeamStore = new AddToTeamStore(makeOpts())
    this.documentTagsScreenStore = new DocumentTagsScreenStore(makeOpts())
    this.documentTagEditorStore = new DocumentTagEditorStore(makeOpts())
    this.skillTagsScreenStore = new SkillTagsScreenStore(makeOpts())
    this.skillTagEditorStore = new SkillTagEditorStore(makeOpts())
    this.skillTagStore = new SkillTagStore(makeOpts())
    this.legendEditorViewStore = new LegendEditorViewStore(makeOpts())
    this.workspaceManagementStore = new WorkspaceManagementStore(makeOpts())
    this.analyticsScreenStore = new AnalyticsScreenStore(makeOpts())
    this.notificationPreferencesStore = new NotificationPreferencesStore(
      makeOpts()
    )
    this.notificationActionStore = new NotificationActionStore(makeOpts())
    this.notificationStore = new NotificationStore(makeOpts())
    this.multiWorkspaceNotificationStore = new MultiWorkspaceNotificationStore(
      makeOpts()
    )
    this.messageBankStore = new MessageBankStore(makeOpts())
    this.messageBankScreenStore = new MessageBankScreenStore(makeOpts())
  }

  onTogglesInitialized() {
    this._togglesInitialized.next(true)
  }
}

/**
 * Whether the Payouts feature is enabled.
 * Used to conditionally construct infrastructure related to payouts.
 */
function isPayoutsEnabled() {
  return Boolean(env.BIGMONEY_API)
}
