import { observable } from 'mobx'
import { validationContext, func, required } from 'validx'
import { vif } from 'utils/validx-validators'

export default class DiyConfigViewModel {
  @observable
  consultationLegendId = undefined

  @observable
  upsellConsultations = false

  @observable
  amountToCharge = undefined

  @observable
  chargeClient = false

  @observable
  workspaceConsultationLegends = []

  validation = validationContext(this, {
    consultationLegendId: [vif(() => this.upsellConsultations, [required()])],
    amountToCharge: [
      vif(() => this.chargeClient, [
        required(),
        func((opts) => {
          return opts.value > 0
        }, 'Amount to charge must be higher than 0'),
      ]),
    ],
  })

  constructor({ legendStore, workspaceId }) {
    this.legendStore = legendStore
    this.workspaceId = workspaceId
  }

  activate(
    consultationLegendId = undefined,
    amountToCharge = undefined,
    chargeClient = false,
    upsellConsultations = false
  ) {
    this.consultationLegendId = consultationLegendId
    this.amountToCharge = amountToCharge
    this.chargeClient = chargeClient
    this.upsellConsultations = upsellConsultations

    this.workspaceConsultationLegends = this.legendStore.briefConsultationLegendsForWorkspace(
      this.workspaceId
    )
  }

  toJSON() {
    return {
      consultation_legend_id: this.consultationLegendId,
      amount_to_charge: isNaN(this.amountToCharge)
        ? undefined
        : this.amountToCharge,
      charge_client: this.chargeClient,
      upsell_consultations: this.upsellConsultations,
    }
  }
}
